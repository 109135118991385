var exports = {};
exports = escapes;
var defaults = ["\\", "`", "*", "{", "}", "[", "]", "(", ")", "#", "+", "-", ".", "!", "_", ">"];
var gfm = defaults.concat(["~", "|"]);
var commonmark = gfm.concat(["\n", "\"", "$", "%", "&", "'", ",", "/", ":", ";", "<", "=", "?", "@", "^"]);
escapes.default = defaults;
escapes.gfm = gfm;
escapes.commonmark = commonmark; // Get markdown escapes.

function escapes(options) {
  var settings = options || {};

  if (settings.commonmark) {
    return commonmark;
  }

  return settings.gfm ? gfm : defaults;
}

export default exports;